<template>
	<div class="detail">
		<div class="con">
			<div class="title">
				<h4>{{content.title}}</h4>
			</div>
			<div class="label">
				<button v-for="(items,ins) in content.label">{{items}}</button>
			</div>
			<img v-if="content.image" :src="imageUrl+content.image" alt="" class="image" />
			<img v-else src="" alt="" class="image" />
			<div class="content">
				<p>{{content.content}}</p>
			</div>
			<div class="page_view">
				<van-icon name="browsing-history-o" />
				<p>{{content.page_view}}</p>
			</div>
			<div class="create_time">
				<p>{{content.create_time}}</p>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		articleDetail
	} from '@/api/index'
	import
	http
	from '../../utils/config.js'
	export default {
		data() {
			return {
				content: '',
				imageUrl:http.imageUrl,
			}
		},
		methods: {
			load() {
				let params = {}
				params.id = this.$route.query.id
				articleDetail(params).then((res) => {
					// console.log(res)
					this.content = res.data
				})
			}
		},
		mounted() {
			// console.log(this.$route.query.id) 
			this.load()
		},
	}
</script>

<style lang="less" scoped>
	@import "~@/assets/style/preset.less";

	.detail {
		width: 100%;
		min-height: calc(100vh);
		display: flex;
		justify-content: center;

		.con {
			width: 80%;
			// height: 300px;
			// border: solid 1px red;

			.title {
				width: 100%;
				display: flex;
				justify-content: center;
			}

			.label {
				// width: 100%;
				float: right;
				margin-bottom: 15px;
				button {
					height: 24px;
					padding: 0 6px;
					font-size: 10px;
					color: #323233;
					background-color: #f6f6f6;
					border: 1px solid #dadcde;
					margin: 0 5px;
				}
			}
			.image {
				width: 100%;
				height: 200px;
			}
			.content{
				width: 100%;
				display: flex;
				justify-content: center;
			}
			.page_view{
				display: flex;
				align-items: center;
				justify-content: flex-end;
				// float: right;
				p{
					margin: 0;
					font-size: 14px;
				}
			}
			.create_time{
				float: right;
				p{
					font-size: 14px;
				}
			}
		}
	}
</style>
